/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import { QueryClient, QueryClientProvider, Hydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dynamic from 'next/dynamic';
import { appWithTranslation } from 'next-i18next';

// We are using next/router intentionnaly here.
// The new router does not have events. And _app.js canb only remain in pages routing so that's ok
import { useRouter } from 'next/router';

// Utils
import { AppProvider } from '../src/store/AppContext';
import { isSOrder } from '../src/utils/platform';

// Components
import Layout from '../components/layout/Layout/Layout';
import Analytics from '../components/layout/Layout/Analytics';
import AxiosInterceptor from '../components/template/AxiosInterceptor/AxiosInterceptor';
import NoSSR from '../components/atoms/NoSSR/NoSSR';

// Utils
import lockBodyScroll from '../src/utils/lockBodyScroll';
import { getSessionItem } from '../src/utils/storage';

const ModalCgv = dynamic(() => import('../components/organisms/ModalCgv/ModalCgv'));
const AddedProduct = dynamic(() => import('../components/cart/AddedProduct/AddedProduct'), { ssr: false });

import '../styles/globals.scss';
import PageLoader from '../components/layout/PageLoader/PageLoader';

const GTM_KEY = isSOrder() ? process.env.NEXT_PUBLIC_GTM_KEY_SORDER : process.env.NEXT_PUBLIC_GTM_KEY;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 20_000,
      retry: 1,
    },
  },
});

function App({ Component, pageProps, host }) {
  const router = useRouter();

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const handleStart = (url) => setLoading(url !== router.pathname);
    const handleComplete = () => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);

  useEffect(() => {
    lockBodyScroll(loading);
  }, [loading]);

  useEffect(() => {
    if (document.location.host === 'www.eiosv2.eu') {
      document.location = 'https://www.s-order.fr/';
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {/* Hydrate is used to inject server-side requests into client-side
        so their data is available in the first render
      */}
      <Hydrate state={pageProps.dehydratedState}>
        <AppProvider>
          <AxiosInterceptor />
          <Layout key={router.asPath}>
            <Head>
              <title>{getSessionItem('keyAccountContext')?.label || ''}</title>
              <meta name="description" content="" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />
              <meta charSet="utf-8" />
              <link rel="icon" href={getSessionItem('keyAccountContext')?.favicon || '/favicon_transparent.ico'} />
            </Head>

            {GTM_KEY ? (
              <>
                {/* Google Tag Manager  */}
                <Script
                  strategy="afterInteractive"
                  id="gtm-script"
                  dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_KEY}');`,
                  }}
                />

                <noscript
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line max-len
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_KEY}" height="0" width="0" style="display: none; visibility: hidden;" />`,
                  }}
                />
              </>
            ) : null}

            {isMounted ? (
              <Analytics>
                <Component {...pageProps} />
              </Analytics>
            ) : null}

            <AddedProduct />
          </Layout>
          <PageLoader loading={loading} />
          <NoSSR>{isSOrder(host) ? <ModalCgv modalId="accept-cgv" /> : null}</NoSSR>
        </AppProvider>
        <ReactQueryDevtools position="bottom-right" />
      </Hydrate>
    </QueryClientProvider>
  );
}

// TODO: This disables all SSG Next, we should do in another way, maybe with .env ?
App.getInitialProps = ({ ctx }) => ({
  host: ctx.req.headers.host,
});

export default appWithTranslation(App);
