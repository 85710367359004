import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

// Utils
import { useTranslation } from 'next-i18next';
import { useAppContext } from '../store/AppContext';
import ANALYTICS_ROLES from '../enum/analytics/enumRolesAnalytics';
import getProductName, { capitalizeName } from '../utils/product/getProductName';

// Hooks
import useRouter from './useRouter';

const useDataLayer = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [{ user, keyAccountContext }] = useAppContext();

  const dataLayerPush = (event) => {
    if (window && event) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(function cleanup() {
        this.reset();
      });
      window.dataLayer.push(event);
    }
  };

  const gtmEventPageView = () => {
    const titleTag = document.querySelector('title');

    const pageView = {
      event: 'page_view',
      pageType: 'Ecommerce',
      path: router.pathname,
      pageTitle: titleTag?.innerText || '',
      client_id: user?.clientCode,
    };

    if (keyAccountContext?.label) pageView.plateforme = keyAccountContext.label;
    if (user?.roles) pageView.role = ANALYTICS_ROLES[user.roles] ? ANALYTICS_ROLES[user.roles] : user.roles;

    dataLayerPush(pageView);
  };

  const formatProductName = (product, category) => {
    const productName =
      product && category && getProductName(t, product, category)
        ? capitalizeName(getProductName(t, product, category))
        : '';
    return productName;
  };

  const gtmEventViewProduct = (product, category) => {
    dataLayerPush({ event: 'Voir le détail', produit: formatProductName(product, category) });
  };

  const gtmEventAddToCart = (product, category) => {
    dataLayerPush({
      event: 'ajout_panier',
      item_name: formatProductName(product, category),
      page_type:
        product.bigVolumeMinimum != null
          ? t('dataLayerEvents.addToCart.big_volume')
          : t(`dataLayerEvents.addToCart.${category}`),
    });
  };

  const gtmEventQuickAddToCart = (product, category) => {
    dataLayerPush({
      event: 'ajout_rapide_panier',
      item_name: formatProductName(product, category),
      page_type:
        product.bigVolumeMinimum != null
          ? t('dataLayerEvents.addToCart.big_volume')
          : t(`dataLayerEvents.addToCart.${category}`),
    });
  };

  const gtmEventSearch = (category, searchValues) => {
    const cleanedValues = Object.values(searchValues).filter(
      (v) => !isNil(v) && (typeof v === 'number' || !isEmpty(v)),
    );
    dataLayerPush({
      event: t(`dataLayerEvents.search.category.${category}`),
      champs: cleanedValues.length,
    });
  };

  const gtmEventReinitSearch = (category) => {
    dataLayerPush({
      event: t(`dataLayerEvents.search.reset.${category}`),
    });
  };

  const gtmEventShowMoreOffers = (product, category) => {
    dataLayerPush({
      event: 'voir_dispo',
      item: formatProductName(product, category),
    });
  };

  const gtmEventConfirmOrder = () => {
    dataLayerPush({
      event: 'confirmer_commande',
    });
  };

  const gtmEventPayOrder = () => {
    dataLayerPush({
      event: 'valider_commande',
    });
  };

  const gtmEventDownloadBl = () => {
    dataLayerPush({
      event: 'telechargement_bdc',
    });
  };

  const gtmEventContact = () => {
    dataLayerPush({
      event: 'nous_contacter',
    });
  };

  const gtmEventSaveSpareParts = () => {
    dataLayerPush({
      event: 'ajout_module_pieces_detachees',
    });
  };

  const gtmEventShowSpareParts = () => {
    dataLayerPush({
      event: 'recherche_pieces_detachees',
    });
  };

  const gtmEventShowDetailedDimensions = (category) => {
    dataLayerPush({
      event: 'detail_dimension',
      recherche: t(`products.categories.${category}`),
    });
  };

  return {
    dataLayerPush,
    gtmEventPageView,
    gtmEventViewProduct,
    gtmEventAddToCart,
    gtmEventSearch,
    gtmEventReinitSearch,
    gtmEventQuickAddToCart,
    gtmEventShowMoreOffers,
    gtmEventConfirmOrder,
    gtmEventPayOrder,
    gtmEventDownloadBl,
    gtmEventContact,
    gtmEventSaveSpareParts,
    gtmEventShowDetailedDimensions,
    gtmEventShowSpareParts,
    formatProductName,
  };
};

export default useDataLayer;
