import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

// Components
import Button from '../../atoms/Button/Button';
import useLogout from '../../../src/hooks/useLogout';

const Modal = dynamic(() => import('../../organisms/Modal/Modal'), { ssr: false });

function Layout({ children }) {
  const { t } = useTranslation();
  const logout = useLogout();

  return (
    <div>
      {children}

      <Modal
        id="logoutConfirm"
        title={t('login.logout.confirm.title')}
        maxWidth={455}
        buttons={(
          <>
            <Button
              color="inverted"
              aria-label="close"
              data-micromodal-close
            >
              {t('cancel')}
            </Button>
            <Button onClick={logout}>
              {t('login.logout.confirm.cta')}
            </Button>
          </>
        )}
      >
        <p>
          {t('login.logout.confirm.text')}
        </p>
      </Modal>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
