import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

// Utils
import { isEmpty } from 'lodash';
import handleVersionCheck from './handleVersionCheck';
import appReducer from './appReducer';
import {
  clearLogout,
  clearStorageAll,
  getSessionItem,
  getStorageItem,
  setSessionItem,
  setStorageItem,
} from '../utils/storage';
import { TYRES } from '../enum/search/modules';
import useRouter from '../hooks/useRouter';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const initStore = {
  // user
  user: null,
  impersonateUser: null,

  // cart
  cart: {},
  newItemAdded: false,

  // products search
  currentVariation: null,
  homeBannerType: TYRES,
  currentSearchPage: 1,
  itemsPerPage: 25,
  searchFilters: {},

  apiCached: {},

  // key account
  keyAccountContext: null,
  kaContextReloadCode: null,
  isLoadingContext: false,
  impersonateKeyAccountContext: null,
};

function AppProvider({ children }) {
  const router = useRouter();

  const [state, dispatch] = useReducer(
    (reducerState, action) => appReducer(
      action,
      reducerState,
      clearStorageAll,
      initStore,
      setStorageItem,
      setSessionItem,
      clearLogout,
    ),
    initStore,
  );

  const populateFromStorage = (key, reducerType, sessionStorage) => {
    if (!state[key] || isEmpty(state[key])) {
      const stored = sessionStorage ? getSessionItem(key) : getStorageItem(key);
      if (stored) dispatch({ type: reducerType, payload: stored });
    }
  };

  useEffect(() => {
    populateFromStorage('user', 'SET_USER');
    populateFromStorage('cart', 'SET_CART');
    populateFromStorage('currentVariation', 'SET_CURRENT_VARIATION');
    populateFromStorage('apiCached', 'SET_API_CACHE');
    populateFromStorage('impersonateUser', 'SET_IMPERSONATE_USER_TOKEN');
    populateFromStorage('keyAccountContext', 'SET_KEY_ACCOUNT_CONTEXT', true);
    populateFromStorage('impersonateKeyAccountContext', 'SET_IMPERSONATE_KEY_ACCOUNT_CONTEXT', true);
    populateFromStorage('isLoadingContext', 'SET_LOADING_CONTEXT');

    handleVersionCheck(getStorageItem, setStorageItem, dispatch, router);
  }, []);

  useEffect(() => {
    if (!state.user && getStorageItem('user')) populateFromStorage('user', 'SET_USER');
  }, [state.user]);

  useEffect(() => {
    if (
      state.user?.email
      && state.cart?.owner
      && state.user.email !== state.cart.owner
    ) {
      dispatch({ type: 'SET_CART', payload: {} });
    }
  }, [state]);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => [useContext(AppStateContext), useContext(AppDispatchContext)];

export {
  AppProvider,
  AppStateContext,
  AppDispatchContext,
  useAppContext,
};
