export const NEXT = {
  CATALOG: {
    RESULTS: (category = '') => `/product/${category}`,
    DETAILS: (category = '', id = '') => `/product/${category}/${id}`,
  },
  CHECKOUT: (step = '') => `/checkout/${step}`,
  ORDERS_SORDER_TYRE: () => '/orders-sorder-tyres',
  LOGOUT: () => '/logout',
  LOGIN: () => '/login',
  NATIONWIDE_OPERATIONS: {
    LIST: () => '/admin/nationwide-operations',
    CREATE: () => '/admin/nationwide-operations/create',
    EDIT: (id) => `/admin/nationwide-operations/${id}`,
    DEFAULT_CARTS: (id) => `/admin/nationwide-operations/${id}/default-carts`,
    LOGISTIC_DISCOUNTS: (id) => `/admin/nationwide-operations/${id}/logistic-discounts`,
    SPECIFIC_DISCOUNTS: (id) => `/admin/nationwide-operations/${id}/specific-discounts`,
    PARTICIPATIONS: {
      LIST: (id) => `/admin/nationwide-operations/${id}/participations`,
      EDIT: (id, participationId) => `/admin/nationwide-operations/${id}/participations/${participationId}`,
      CREATE: (id, participationId) => `/admin/nationwide-operations/${id}/participations/${participationId}/create`,
      UPDATE_CART: (id, participationId, participationChildId) =>
        `/admin/nationwide-operations/${id}/participations/${participationId}/${participationChildId}`,
      UPDATE_SHIPPING: (id, participationId, participationChildId) =>
        `/admin/nationwide-operations/${id}/participations/${participationId}/${participationChildId}/shipping`,
    },
  },
  PARTICIPATIONS: {
    LIST: () => '/nationwide-operations',
    EDIT: (id) => `/nationwide-operations/${id}`,
    CREATE: (id) => `/nationwide-operations/${id}/participation/create`,
    UPDATE_CART: (id, participationId) => `/nationwide-operations/${id}/participation/${participationId}`,
    UPDATE_SHIPPING: (id, participationId) => `/nationwide-operations/${id}/participation/${participationId}/shipping`,
  },
};

export const API = {
  USER: {
    ME: () => '/user/me',
  },
  CATALOGUE: {
    MODULE: (module = '') => `/catalogue/${module}`,
  },
  REFRESH_TOKEN: () => 'token/refresh',
};
