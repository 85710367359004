const isBrowser = (() => typeof window !== 'undefined')();

export const getStorageItem = (key) =>
  isBrowser && window.localStorage[key] ? JSON.parse(window.localStorage[key]) : '';

export const setStorageItem = (key, value) => {
  if (isBrowser) {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  }

  return false;
};

const removeStorageItem = (key) => {
  if (isBrowser && window.localStorage[key]) {
    window.localStorage.removeItem(key);
  }
};

export const clearStorageAll = () => {
  // clear all
  if (isBrowser && window.localStorage && Object.keys(window.localStorage)?.length) {
    const allKeysButVersion = Object.keys(window.localStorage).filter((key) => key !== 'appVersion');
    allKeysButVersion.forEach((key) => removeStorageItem(key));
  }
};

export const clearLogout = () => {
  // clear all
  if (isBrowser && window.localStorage && Object.keys(window.localStorage)?.length) {
    const allKeysButVersion = Object.keys(window.localStorage).filter(
      (key) => key !== 'appVersion' && key !== 'apiCached',
    );
    allKeysButVersion.forEach((key) => removeStorageItem(key));
  }
};

// SESSION STORAGE

export const getSessionItem = (key) =>
  isBrowser && window.sessionStorage[key] ? JSON.parse(window.sessionStorage[key]) : '';

export const setSessionItem = (key, value) => {
  if (isBrowser) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  }

  return false;
};

// COOKIES

export const setCookie = (name, value) => {
  const date = new Date();
  date.setTime(date.getTime() + 14 * 24 * 60 * 60 * 1000);

  const expires = `; expires=${date.toUTCString()}`;

  document.cookie = `${name}=${JSON.stringify(value)}${expires}; path=/`;
};

export const removeCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
};
