import { useEffect } from 'react';

// Store
import { useAppContext } from '../../../src/store/AppContext';

// Hooks
import useRouter from '../../../src/hooks/useRouter';
import useDataLayer from '../../../src/hooks/useDataLayer';

// Utils
import { NEXT } from '../../../src/constants/routes';

function Analytics({ children }) {
  const router = useRouter();
  const [{ keyAccountContext }] = useAppContext();
  const { gtmEventPageView } = useDataLayer();

  let pageViewPushed = false;

  useEffect(() => {
    if (router.pathname !== NEXT.LOGOUT() && keyAccountContext && !pageViewPushed) {
      setTimeout(() => {
        gtmEventPageView();
        pageViewPushed = true;
      }, 0);
    }
  }, [keyAccountContext]);

  return children;
}

Analytics.propTypes = {};

export default Analytics;
