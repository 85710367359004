/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Utils
import { Oval } from 'react-loader-spinner';

import styles from './Button.module.scss';

const Button = forwardRef(({
  type,
  children,
  color,
  full,
  small,
  className,
  isLoading,
  disabled,
  ...prop
}, ref) => (
  <button
    className={`
      ${styles.button} 
      ${full ? styles.full : ''}
      ${className || ''}
      ${color ? styles[color] : ''}
      ${small ? styles.small : ''}
    `}
    type={type}
    disabled={disabled || isLoading}
    {...prop}
    ref={ref}
  >
    {isLoading ? (
      <div className={`${styles.loader} m-r-10`}>
        <Oval
          isLoading
          height={20}
          width={20}
          strokeWidth={3}
          color="#a1a1a1"
          secondaryColor="#bfbfbf"
          ariaLabel="loading"
        />
      </div>
    ) : null}
    {children}
  </button>
));

Button.displayName = 'Button';

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['inverted', 'primary_inverted_white', 'primary_light', 'simple_primary', 'error']),
  full: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  color: null,
  full: false,
  small: false,
  className: null,
  isLoading: false,
  disabled: false,
};

export default Button;
