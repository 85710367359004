import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

// Context
import { useAppContext } from '../store/AppContext';
import { removeCookie } from '../utils/storage';

// Hooks
import useRouter from './useRouter';

const useLogout = () => {
  const [, dispatch] = useAppContext();
  const router = useRouter();
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    removeCookie('token');
    dispatch({ type: 'RESET_LOGOUT', payload: null });
    queryClient.clear();
    router.push('/login');
  }, [dispatch, router, queryClient]);
  return logout;
};

export default useLogout;
