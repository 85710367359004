import { cloneDeep } from 'lodash';
import { useAppContext } from '../store/AppContext';

// Hooks
import useRouter from './useRouter';

const useImpersonate = () => {
  const router = useRouter();
  const [{ impersonateUser, impersonateKeyAccountContext }, stateDispatch] = useAppContext();

  const handleImpersonateLogout = () => {
    if (impersonateUser) {
      stateDispatch({ type: 'SET_LOADING_CONTEXT', payload: true });

      const impersonate = cloneDeep(impersonateUser);
      const redirect = impersonate?.redirectUrl || null;

      stateDispatch({
        type: 'SET_USER',
        payload: impersonate.adminUser,
      });

      stateDispatch({ type: 'SET_KA_CONTEXT_RELOAD_CODE', payload: null });
      stateDispatch({ type: 'SET_KEY_ACCOUNT_CONTEXT', payload: impersonateKeyAccountContext });
      stateDispatch({ type: 'SET_IMPERSONATE_USER_TOKEN', payload: null });
      stateDispatch({ type: 'SET_IMPERSONATE_KEY_ACCOUNT_CONTEXT', payload: null });

      if (redirect) {
        router.push(redirect);
        stateDispatch({ type: 'SET_LOADING_CONTEXT', payload: false });
      } else {
        stateDispatch({ type: 'SET_LOADING_CONTEXT', payload: false });
      }
    }
  };

  return {
    handleImpersonateLogout,
  };
};

export default useImpersonate;
