import isNil from 'lodash/isNil';

// Utils
import { RIMS, TYRES } from '../../enum/search/modules';

/* eslint-disable max-len */
export const capitalizeName = (string) => (string?.length > 1 && string.split(' ')?.length ? string.split(' ').filter((word) => !isNil(word) && word !== '').map((word) => word[0].toUpperCase() + word.substring(1)).join(' ') : string);

const getProductName = (t, product, category) => {
  let name = '';
  switch (category) {
    case TYRES:
      name = `${category ? t(`products.categories.single.${category}`) : ''} ${product.brand?.brandName ? product.brand?.brandName?.toLowerCase() : product.brand?.label?.toLowerCase()} ${product.profil ? product.profil?.toLowerCase() : ''}`;
      break;
    case RIMS:
      name = `${category ? t(`products.categories.single.${category}`) : ''} ${product.finition ? product.finition?.toLowerCase() : ''} ${product.brand?.brandName ? product.brand?.brandName?.toLowerCase() : product.brand?.label?.toLowerCase()}`;
      break;
    default:
      name = `${category ? t(`products.categories.single.${category}`) : ''} ${product.brand?.brandName ? product.brand?.brandName?.toLowerCase() : product.brand?.label?.toLowerCase()}`;
  }

  return name?.replace('  ', ' ') || '';
};

export default getProductName;
